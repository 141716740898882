.header {
    border-bottom: 1px solid var(--surface-200);
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    
}



  @media (min-width: 768px) {
    .header {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .header {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .header {
      width: 1170px;
    }
  }