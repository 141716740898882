.profileListContainer {
    margin-top: 0.8rem;
    border: 1px solid  var(--surface-100);
}

.header {
    padding: 0.5rem;
    background-color: var(--surface-100);
    color: var(--text-color);
}

.profileList {
    padding:1rem;
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
}