

.gameItem {
    margin-bottom: 1rem;
    border: 1px solid var(--surface-200);
    color: var( --text-color);
}

.title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}