.workarea {
    min-height: 700px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    margin-top: 5px;

}

  @media (min-width: 768px) {
    .workarea {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .workarea {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .workarea {
      width: 1170px;
    }
  }