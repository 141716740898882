.footer {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-height: 2rem;
    padding: 1rem;
    border-top: 1px solid var(--surface-200);
    font-size: 0.8rem;
    z-index: 100;
    background-color: var(--surface-a);
}